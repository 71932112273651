import { InjectionToken } from '@angular/core';

// referrals-f42f82d467 will be replaced during build step,
// it is used by sentry to match sourcemaps

export const AppRevisionToken = new InjectionToken('APP_REVISION');
export const AppRevisionProvider = {
  provide: AppRevisionToken,
  useValue: `referrals-f42f82d467`,
};
